<template>
  <div class="reviewSchedule">
    <el-dialog
      :title="
        indexTeacher.teacherName +
        (indexTeacher.type == 4 ? ` 仲裁列表` : ` 批阅列表`)
      "
      :visible.sync="dialogVisible"
      :width="indexTeacher.type != 19 ? '944px' : '1200px'"
      :close-on-click-modal="false"
    >
      <div class="model-search-box">
        学校：
        <el-select
          v-model="search.schoolId"
          placeholder="全部学校"
          @change="searchTeacher()"
        >
          <el-option label="全部学校" value=""> </el-option>
          <el-option
            v-for="item in schoolList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select>
        搜索：
        <el-input
          v-model="search.keyword"
          placeholder="输入姓名/考号"
          @keyup.enter.native="searchTeacher"
        >
          <i
            slot="suffix"
            class="el-icon-search el-input__icon"
            @click="searchTeacher"
          >
          </i
        ></el-input>
      </div>
      <el-table
        :key="tabKey"
        v-loading="listLoading"
        :data="studentList"
        border
        style="width: 100%"
      >
        <el-table-column
          v-if="indexTeacher.type != 19"
          prop="rowNum"
          label="批阅顺序"
          align="center"
        >
        </el-table-column>
        <template v-if="indexTeacher.type == 19">
          <el-table-column prop="teacherName" label="报警人" align="center">
          </el-table-column>
          <el-table-column
            prop="teacherUsername"
            label="教师账号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="scoreDirectCreateTime"
            label="报警时间"
            align="center"
            width="180px"
          >
          </el-table-column>
          <el-table-column label="异常类型" align="center">
            <template slot-scope="{ row }">
              {{ row.type | setName(exceptionTypes) }}
            </template>
          </el-table-column>
        </template>
        <el-table-column prop="studentName" label="考生姓名" align="center">
        </el-table-column>
        <el-table-column prop="studentExamId" label="考号" align="center">
        </el-table-column>
        <el-table-column prop="schoolName" label="学校名称" align="center">
        </el-table-column>
        <el-table-column
          prop="score"
          label="题目给分"
          width="80px"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="indexTeacher.type != 19"
          prop="timeUse"
          label="批阅时间（s）"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="indexTeacher.type == 19"
          prop="scoreDirectTime"
          label="处理阅卷时间"
          align="center"
          width="180px"
        >
        </el-table-column>
        <!--  -->
        <el-table-column prop="studentExamId" label="批阅类型" align="center">
          <template slot-scope="{ row }">
            <template v-if="row.markType == 1">一评</template>
            <template v-if="row.markType == 2">二评</template>
            <template v-if="row.markType == 3">三评</template>
            <template v-if="row.markType == 4">仲裁</template>
            <template v-if="row.markType == 19">直接打分</template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="total > 1"
        :current-page="search.current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="search.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import { teacherMarkV2 } from "@/core/api/exam/paperMonitor";
import { scoreRulesList } from "@/core/util/constdata";
import { exceptionTypes } from "@/core/util/constdata.js";
import { allschoollistbyexamid } from "@/core/api/exam/examTeacher";
export default {
  name: "ReviewSchedule",
  components: {},
  data() {
    return {
      dialogVisible: false,
      tabLoading: false,
      listLoading: false,
      indexType: 1,
      tabKey: 1,
      search: {
        schoolId: "",
        keyword: "",
        current: 1,
        size: 10,
      },
      total: 0,
      studentList: [],
      scoreRulesList: scoreRulesList,
      exceptionTypes: exceptionTypes,
      schoolList: [],
      indexTeacher: {},
      indexQeustion: {},
    };
  },
  created() {},
  methods: {
    handleSizeChange(val) {
      this.search.size = val;
      this.searchTeacher();
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getList();
    },
    async init(row, item) {
      this.indexTeacher = row;
      this.indexQeustion = item;
      this.dialogVisible = true;
      if (this.$route.query.groupId) {
        this.getSchoollistbyexamid();
      } else {
        this.schoolList = this.$parent.schoolList.filter(
          (item) => item.schoolId != -520
        );
      }

      this.search.keyword = "";
      this.search.schoolId = "";
      this.tabKey = new Date().getTime();
      this.searchTeacher();
    },
    async getSchoollistbyexamid() {
      const res = await allschoollistbyexamid({
        examId: this.$route.query.examId,
        groupId: this.$route.query.groupId,
      });

      this.schoolList = res.data.data;
    },
    getList() {
      let data = {
        teacherId: this.indexTeacher.teacherId,
        sheetDetailId: this.indexQeustion.sheetDetailId,
        examPaperId: this.indexQeustion.examPaperId,
        groupId: this.$route.query.groupId,
      };
      if (this.indexTeacher.type == 1 || this.indexTeacher.type == 2) {
        data.type = 1;
      }
      if (this.indexTeacher.type == 3) {
        data.type = 2;
      }
      if (this.indexTeacher.type == 4) {
        data.type = 3;
      }
      if (this.indexTeacher.type == 19) {
        data.type = 19;
      }
      this.listLoading = true;
      Object.assign(data, this.search);
      teacherMarkV2(data)
        .then((res) => {
          this.studentList = res.data.data.records;
          this.total = res.data.data.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    searchTeacher() {
      this.search.current = 1;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.reviewSchedule {
  .model-search-box {
    margin-bottom: 18px;
    .el-select {
      width: 140px;
      margin-right: 12px;
    }
    .el-input {
      width: 200px;
    }
  }
}
</style>
