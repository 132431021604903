var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionTab"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","span-method":_vm.objectSpanMethod}},[_c('el-table-column',{attrs:{"prop":"questionNum","label":"题目","width":"180","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.isOption && scope.row.examQuestionGroup)?[_vm._v("选做题： ")]:_vm._e(),(!scope.row.isOption)?[_vm._v("主观题：")]:_vm._e(),_vm._v(" "+_vm._s(scope.row.questionNum)+" ")]}}])}),(!_vm.showTableType)?[_c('el-table-column',{attrs:{"width":"180","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 整体进度 "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Top Left 提示文字","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 此处展示为题目的已完成有效卡数量/该题有效卡总数，"),_c('br'),_vm._v(" 若该题为多评则是（已完成有效卡数*2+已三评数+已仲裁数）/（该题所有答题卡数*2+需三评数+需仲裁数）"),_c('br'),_vm._v(" 注意：若题目存在已经直接打分有效卡，计入到对应题目的整体完成进度，但不计入任何一学校的进度中 ")]),_c('i',{staticClass:"el-icon-question"})])]},proxy:true},{key:"default",fn:function(scope){return [_c('span',{class:{
              err: scope.row.readCount < scope.row.denominator,
            }},[_vm._v(" "+_vm._s(scope.row.readCount ? scope.row.readCount : 0)+"/"+_vm._s(scope.row.denominator)+" ")])]}}],null,false,1212109079)})]:_vm._e(),(_vm.showTableType == 1)?[(
          _vm.v.rollingMethod == 0 ||
          (_vm.v.rollingMethod == 1 &&
            _vm.groupScoringMode == 1 &&
            _vm.groupRollingMethod == 0)
        )?_c('el-table-column',{attrs:{"width":"160","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(
              _vm.v.rollingMethod == 1 &&
              _vm.groupScoringMode == 1 &&
              _vm.groupRollingMethod == 0
            )?[_vm._v(" 阅卷组进度 ")]:[_vm._v(" 整体进度 ")],_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 此处展示为本场考试的 已完成的卡数/整体未被批阅的有效卡数，"),_c('br'),_vm._v(" 若该题为多评则是 已完成的卡数*2+三评/整体未被批阅的有效卡数*2+三评"),_c('br'),_vm._v(" 如果某题是多评，则整体进度里不包含仲裁、但包含三评的需阅量，随阅卷进度动态变化。 ")]),_c('i',{staticClass:"el-icon-question"})])]},proxy:true},{key:"default",fn:function({ row }){return [_c('span',{class:{
              err: row.wholeReadCount < row.wholeDenominator,
            }},[_vm._v(" "+_vm._s(row.wholeReadCount)+"/"+_vm._s(row.wholeDenominator)+" ")])]}}],null,false,3797651432)}):_vm._e(),(_vm.groupScoringMode == 1 && _vm.v.rollingMethod == 0)?_c('el-table-column',{attrs:{"width":"160","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 阅卷组进度 "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 此处展示为本场考试阅卷组进度的 已完成的卡数/整体未被批阅的有效卡数，"),_c('br'),_vm._v(" 若该题为多评则是 已完成的卡数*2+三评/整体未被批阅的有效卡数*2+三评"),_c('br'),_vm._v(" 如果某题是多评，则阅卷组进度里不包含仲裁、但包含三评的需阅量，随阅卷进度动态变化。 ")]),_c('i',{staticClass:"el-icon-question"})])]},proxy:true},{key:"default",fn:function({ row }){return [_c('span',{class:{
              err:
                row.wholeGroupReadCount < row.wholeGroupDenominator &&
                row.wholeReadCount < row.wholeDenominator,
            }},[_vm._v(" "+_vm._s(row.wholeGroupReadCount)+"/"+_vm._s(row.wholeGroupDenominator)+" ")])]}}],null,false,1457571053)}):_vm._e(),_c('el-table-column',{attrs:{"width":"160","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.$route.name == 'groupProgress')?[_vm._v(" 阅卷组进度 ")]:[_vm._v("学校进度")],_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Top Left 提示文字","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 此处展示为学校的已完成有效卡数量/被分配任务数，"),_c('br'),_vm._v(" 若该题为多评则是（已完成有效卡数*2+已三评数+已仲裁数）/（被分配任务数*2+需三评数+需仲裁数）"),_c('br'),_vm._v(" 注意：若题目存在已经直接打分有效卡，计入到对应题目的整体完成进度，但不计入任何一学校的进度中 ")]),_c('i',{staticClass:"el-icon-question"})])]},proxy:true},{key:"default",fn:function({ row }){return [_c('span',{class:{
              err: _vm.getRollErr(row),
            }},[_vm._v(" "+_vm._s(_vm.getRollNum(row))+" ")])]}}],null,false,3427803463)})]:_vm._e(),_c('el-table-column',{attrs:{"width":"200","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(!_vm.showTableType)?[_vm._v(" 任务完成进度 "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 此处展示为题目 已完成有效卡数量/该题整体总数，"),_c('br'),_vm._v(" 若该题为多评则是 已完成有效卡数*2/该题任务数*2 ")]),_c('i',{staticClass:"el-icon-question"})])]:_vm._e(),(_vm.showTableType == 1)?[(_vm.v.scoringMode == 2 && _vm.$route.name == 'groupProgress')?[_vm._v(" 阅卷组进度详情 ")]:[_vm._v("学校进度详情")]]:_vm._e()]},proxy:true},{key:"default",fn:function({ row }){return [(row.isOption && !row.examQuestionGroup)?void 0:[(row.type == 1)?_c('span',{class:{ err: _vm.getErr(row, _vm.v, 1) }},[(row.ruleScore == 1)?[_vm._v("单评：")]:_vm._e(),(row.ruleScore == 2 || row.ruleScore == 3)?[_vm._v(" 一、二评： ")]:_vm._e(),(row.ruleScore == 4)?[_vm._v(" 部分双评： ")]:_vm._e(),_vm._v(" "+_vm._s(_vm._f("getListNums")(row.detailVOList,1))+" ")],2):_vm._e(),(row.type == 3)?_c('span',{class:{ err: _vm.getType3(row) }},[_vm._v(" 三评： "+_vm._s(_vm._f("getNums3")(row))+" ")]):_vm._e(),(row.type == 4)?[(row.ifSingle == 1)?_c('span',{staticClass:"task-nums-list",class:{ err: row.arbitrationWorkload > 0 }},[_vm._v(" 仲裁： "+_vm._s(_vm._f("getListNums4")(row,4))+" ")]):_c('span',{staticClass:"task-nums-list",class:{ err: _vm.getNumsT(row.detailVOList, 4) }},[_vm._v(" 仲裁： "+_vm._s(_vm._f("getListNums")(row.detailVOList,4))+" ")])]:_vm._e(),(row.type == 19)?[(
                row.teacherList &&
                row.teacherList[0] &&
                row.teacherList[0].readCount > 0
              )?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showTeacher(row.teacherList[0], row)}}},[_vm._v(" 直接打分： "+_vm._s(row.teacherList[0].readCount || 0)+" ")]):_vm._e(),(row.waitAbnormalCount > 0)?_c('span',[_vm._v(" 阅卷告警： "+_vm._s(row.waitAbnormalCount || 0)+" ")]):_vm._e()]:_vm._e()]]}}])}),_c('el-table-column',{attrs:{"label":"教师阅卷情况","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(!_vm.showTableType)?[(_vm.v.scoringMode == 0)?[_vm._v("教师阅卷情况")]:_vm._e(),(_vm.v.scoringMode == 1)?[_vm._v("学校阅卷情况")]:_vm._e(),(_vm.v.scoringMode == 2)?[_vm._v("阅卷组阅卷情况")]:_vm._e()]:_vm._e(),(_vm.showTableType == 1)?[_vm._v("教师阅卷情况")]:_vm._e(),_c('el-checkbox',{staticClass:"check-tips",model:{value:(_vm.showSchoolName),callback:function ($$v) {_vm.showSchoolName=$$v},expression:"showSchoolName"}},[_vm._v("展示学校信息")])]},proxy:true},{key:"default",fn:function({ row }){return [_c('div',{staticClass:"task-load",class:{ 'task-load-opt': row.isOption && !row.examQuestionGroup }},[(row.isOption && !row.examQuestionGroup)?[_c('span',{staticClass:"task-nums-list shik-tips",class:{
                err: row.workloadAll > 0,
              }},[_vm._v(" 待处理："+_vm._s(row.workloadAll || 0)+" ")]),(row.detailVOList && row.detailVOList.length > 0)?[(_vm.userInfo.roleId == 1 || _vm.userInfo.roleId == 6)?[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.detailVOList[0].username +
                    ',' +
                    row.detailVOList[0].schoolName,"placement":"top"}},[_c('span',{staticClass:"task-nums-list",staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(row.detailVOList[0].teacherName)+" ")])])]:_c('span',{staticClass:"task-nums-list",staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(row.detailVOList[0].teacherName)+" ")])]:_c('span',{staticClass:"err task-nums-list"},[_vm._v("未设置处理人")]),_c('span',{staticClass:"shik-tips"},[_vm._v(" 空白题："+_vm._s(row.isBlankCount)+" ")])]:[_vm._l((row.teacherList),function(m,n){return [(
                  m.isSchool != 1 &&
                  (_vm.userInfo.roleId == 1 || _vm.userInfo.roleId == 6)
                )?[_c('el-tooltip',{key:n,staticClass:"item",attrs:{"effect":"dark","content":m.username + ',' + m.schoolName,"placement":"top"}},[_c('span',{staticClass:"task-nums-list task-item filter-item teacher-item",class:{
                      succ: m.typeSucc,
                      err: m.typeErr,
                      'err-tips': m.readCount == 0 && m.typeErr,
                      'show-tips': _vm.getShow(m, row),
                    },on:{"click":function($event){return _vm.showTeacher(m, row)}}},[(_vm.showSchoolName)?[_c('div',[_vm._v(" "+_vm._s(m.teacherName)+" "),(row.type != 4)?_c('span',[_vm._v(" ("+_vm._s((m.readCount || 0) + "/" + m.workload)+") ")]):_vm._e(),(row.type == 4)?_c('span',[_vm._v(" ("+_vm._s(m.readCount || 0)+") ")]):_vm._e()]),_vm._v(" "+_vm._s(m.schoolName)+" ")]:[_vm._v(" "+_vm._s(m.teacherName)+" "),(row.type != 4)?_c('span',[_vm._v("("+_vm._s((m.readCount || 0) + "/" + m.workload)+")")]):_vm._e(),(row.type == 4)?_c('span',[_vm._v("("+_vm._s(m.readCount || 0)+")")]):_vm._e()]],2)])]:[_c('span',{key:n,staticClass:"task-nums-list task-item filter-item",class:{
                    succ: m.typeSucc,
                    err: m.typeErr,
                    'show-tips': _vm.getShow(m, row),
                  },on:{"click":function($event){return _vm.showTeacher(m, row)}}},[_vm._v(" "+_vm._s(m.teacherName)+" "),(row.type != 4)?_c('span',[_vm._v("("+_vm._s((m.readCount || 0) + "/" + m.workload)+")")]):_vm._e(),(row.type == 4)?_c('span',[_vm._v("("+_vm._s(m.readCount || 0)+")")]):_vm._e()])]]})]],2)]}}])})],2),_c('reviewSchedule',{ref:"reviewSchedule"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }