<template>
  <div class="questionTab">
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :span-method="objectSpanMethod"
    >
      <el-table-column
        prop="questionNum"
        label="题目"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.isOption && scope.row.examQuestionGroup"
            >选做题：
          </template>
          <template v-if="!scope.row.isOption">主观题：</template>
          {{ scope.row.questionNum }}
        </template>
      </el-table-column>

      <template v-if="!showTableType">
        <el-table-column width="180" align="center">
          <template #header>
            整体进度
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top-start"
            >
              <div slot="content">
                此处展示为题目的已完成有效卡数量/该题有效卡总数，<br />
                若该题为多评则是（已完成有效卡数*2+已三评数+已仲裁数）/（该题所有答题卡数*2+需三评数+需仲裁数）<br />
                注意：若题目存在已经直接打分有效卡，计入到对应题目的整体完成进度，但不计入任何一学校的进度中
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <span
              :class="{
                err: scope.row.readCount < scope.row.denominator,
              }"
            >
              {{ scope.row.readCount ? scope.row.readCount : 0 }}/{{
                scope.row.denominator
              }}
            </span>
          </template>
        </el-table-column>
      </template>
      <template v-if="showTableType == 1">
        <el-table-column
          v-if="
            v.rollingMethod == 0 ||
            (v.rollingMethod == 1 &&
              groupScoringMode == 1 &&
              groupRollingMethod == 0)
          "
          width="160"
          align="center"
        >
          <template #header>
            <template
              v-if="
                v.rollingMethod == 1 &&
                groupScoringMode == 1 &&
                groupRollingMethod == 0
              "
            >
              阅卷组进度
            </template>
            <template v-else> 整体进度 </template>
            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content">
                此处展示为本场考试的 已完成的卡数/整体未被批阅的有效卡数，<br />
                若该题为多评则是
                已完成的卡数*2+三评/整体未被批阅的有效卡数*2+三评<br />
                如果某题是多评，则整体进度里不包含仲裁、但包含三评的需阅量，随阅卷进度动态变化。
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <span
              :class="{
                err: row.wholeReadCount < row.wholeDenominator,
              }"
            >
              {{ row.wholeReadCount }}/{{ row.wholeDenominator }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="groupScoringMode == 1 && v.rollingMethod == 0"
          width="160"
          align="center"
        >
          <template #header>
            阅卷组进度
            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content">
                此处展示为本场考试阅卷组进度的
                已完成的卡数/整体未被批阅的有效卡数，<br />
                若该题为多评则是
                已完成的卡数*2+三评/整体未被批阅的有效卡数*2+三评<br />
                如果某题是多评，则阅卷组进度里不包含仲裁、但包含三评的需阅量，随阅卷进度动态变化。
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <!-- <template v-if="row.isOption == 1 && row.wholeDenominator == 0">
              <span> 0/0 </span>
            </template>
            <template v-else> -->
            <span
              :class="{
                err:
                  row.wholeGroupReadCount < row.wholeGroupDenominator &&
                  row.wholeReadCount < row.wholeDenominator,
              }"
            >
              {{ row.wholeGroupReadCount }}/{{ row.wholeGroupDenominator }}
            </span>
            <!-- </template> -->
          </template>
        </el-table-column>

        <el-table-column width="160" align="center">
          <template #header>
            <!--  -->

            <template v-if="$route.name == 'groupProgress'">
              阅卷组进度
            </template>
            <template v-else>学校进度</template>
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top-start"
            >
              <div slot="content">
                此处展示为学校的已完成有效卡数量/被分配任务数，<br />
                若该题为多评则是（已完成有效卡数*2+已三评数+已仲裁数）/（被分配任务数*2+需三评数+需仲裁数）<br />
                注意：若题目存在已经直接打分有效卡，计入到对应题目的整体完成进度，但不计入任何一学校的进度中
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <!-- <template v-if="row.isOption == 1 && row.wholeDenominator == 0">
              <span> 0/0 </span>
            </template> -->
            <!--  v-else -->
            <span
              :class="{
                err: getRollErr(row),
              }"
            >
              {{ getRollNum(row) }}
            </span>
          </template>
        </el-table-column>
      </template>

      <el-table-column width="200" align="center">
        <template #header>
          <template v-if="!showTableType">
            任务完成进度
            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content">
                此处展示为题目 已完成有效卡数量/该题整体总数，<br />
                若该题为多评则是 已完成有效卡数*2/该题任务数*2
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template v-if="showTableType == 1">
            <template
              v-if="v.scoringMode == 2 && $route.name == 'groupProgress'"
            >
              阅卷组进度详情
            </template>
            <template v-else>学校进度详情</template>
          </template>
        </template>
        <template slot-scope="{ row }">
          <template v-if="row.isOption && !row.examQuestionGroup"> </template>
          <template v-else>
            <span v-if="row.type == 1" :class="{ err: getErr(row, v, 1) }">
              <template v-if="row.ruleScore == 1">单评：</template>
              <template v-if="row.ruleScore == 2 || row.ruleScore == 3">
                一、二评：
              </template>
              <template v-if="row.ruleScore == 4"> 部分双评： </template>
              {{ row.detailVOList | getListNums(1) }}
            </span>
            <span v-if="row.type == 3" :class="{ err: getType3(row) }">
              三评： {{ row | getNums3() }}
            </span>
            <template v-if="row.type == 4">
              <span
                v-if="row.ifSingle == 1"
                class="task-nums-list"
                :class="{ err: row.arbitrationWorkload > 0 }"
              >
                仲裁： {{ row | getListNums4(4) }}
              </span>
              <span
                v-else
                class="task-nums-list"
                :class="{ err: getNumsT(row.detailVOList, 4) }"
              >
                仲裁： {{ row.detailVOList | getListNums(4) }}
              </span>
            </template>
            <template v-if="row.type == 19">
              <span
                v-if="
                  row.teacherList &&
                  row.teacherList[0] &&
                  row.teacherList[0].readCount > 0
                "
                style="cursor: pointer"
                @click="showTeacher(row.teacherList[0], row)"
              >
                直接打分： {{ row.teacherList[0].readCount || 0 }}
              </span>
              <span v-if="row.waitAbnormalCount > 0">
                阅卷告警： {{ row.waitAbnormalCount || 0 }}
              </span>
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="教师阅卷情况" align="center">
        <template #header>
          <template v-if="!showTableType">
            <template v-if="v.scoringMode == 0">教师阅卷情况</template>
            <template v-if="v.scoringMode == 1">学校阅卷情况</template>
            <template v-if="v.scoringMode == 2">阅卷组阅卷情况</template>
          </template>
          <template v-if="showTableType == 1">教师阅卷情况</template>
          <el-checkbox v-model="showSchoolName" class="check-tips"
            >展示学校信息</el-checkbox
          >
        </template>
        <template slot-scope="{ row }">
          <div
            class="task-load"
            :class="{ 'task-load-opt': row.isOption && !row.examQuestionGroup }"
          >
            <template v-if="row.isOption && !row.examQuestionGroup">
              <span
                class="task-nums-list shik-tips"
                :class="{
                  err: row.workloadAll > 0,
                }"
              >
                待处理：{{ row.workloadAll || 0 }}
              </span>
              <template v-if="row.detailVOList && row.detailVOList.length > 0">
                <template v-if="userInfo.roleId == 1 || userInfo.roleId == 6">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      row.detailVOList[0].username +
                      ',' +
                      row.detailVOList[0].schoolName
                    "
                    placement="top"
                  >
                    <span class="task-nums-list" style="text-align: left">
                      {{ row.detailVOList[0].teacherName }}
                    </span>
                  </el-tooltip>
                </template>
                <span v-else class="task-nums-list" style="text-align: left">
                  {{ row.detailVOList[0].teacherName }}
                </span>
              </template>

              <span v-else class="err task-nums-list">未设置处理人</span>
              <span class="shik-tips"> 空白题：{{ row.isBlankCount }} </span>
            </template>
            <template v-else>
              <template v-for="(m, n) in row.teacherList">
                <template
                  v-if="
                    m.isSchool != 1 &&
                    (userInfo.roleId == 1 || userInfo.roleId == 6)
                  "
                >
                  <el-tooltip
                    :key="n"
                    class="item"
                    effect="dark"
                    :content="m.username + ',' + m.schoolName"
                    placement="top"
                  >
                    <span
                      class="task-nums-list task-item filter-item teacher-item"
                      :class="{
                        succ: m.typeSucc,
                        err: m.typeErr,
                        'err-tips': m.readCount == 0 && m.typeErr,
                        'show-tips': getShow(m, row),
                      }"
                      @click="showTeacher(m, row)"
                    >
                      <template v-if="showSchoolName">
                        <div>
                          {{ m.teacherName }}
                          <span v-if="row.type != 4">
                            ({{ (m.readCount || 0) + "/" + m.workload }})
                          </span>
                          <span v-if="row.type == 4">
                            ({{ m.readCount || 0 }})
                          </span>
                        </div>
                        {{ m.schoolName }}
                      </template>
                      <template v-else>
                        {{ m.teacherName }}
                        <span v-if="row.type != 4"
                          >({{ (m.readCount || 0) + "/" + m.workload }})</span
                        >
                        <span v-if="row.type == 4"
                          >({{ m.readCount || 0 }})</span
                        >
                      </template>
                    </span>
                  </el-tooltip>
                </template>
                <template v-else>
                  <span
                    :key="n"
                    class="task-nums-list task-item filter-item"
                    :class="{
                      succ: m.typeSucc,
                      err: m.typeErr,
                      'show-tips': getShow(m, row),
                    }"
                    @click="showTeacher(m, row)"
                  >
                    {{ m.teacherName }}
                    <span v-if="row.type != 4"
                      >({{ (m.readCount || 0) + "/" + m.workload }})</span
                    >
                    <span v-if="row.type == 4">({{ m.readCount || 0 }})</span>
                  </span>
                </template>
              </template>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <reviewSchedule ref="reviewSchedule"></reviewSchedule>
  </div>
</template>

<script>
import reviewSchedule from "./reviewSchedule.vue";
import { getStore } from "@/core/util/store";
export default {
  name: "QuestionTab",
  components: { reviewSchedule },
  filters: {
    getListNums(list, type) {
      let nums1 = 0;
      let nums2 = 0;
      list.forEach((item) => {
        if (item.type == type || !item.type) {
          nums1 = nums1 + (Number(item.readCount) || 0);
          nums2 = nums2 + (Number(item.workload) || 0);
        }
      });
      return `${nums1}/${nums2}`;
    },
    getNums3(row) {
      let nums1 = 0;
      let { detailVOList, workloadThree } = row;
      detailVOList.forEach((item) => {
        if (item.type == 3) {
          nums1 = nums1 + (Number(item.readCount) || 0);
        }
      });
      return `${nums1}/${nums1 + workloadThree}`;
    },
    getListNums1(list, type) {
      let nums1 = 0;
      let nums2 = 0;
      list.forEach((item) => {
        if (item.type == type) {
          nums1 = nums1 + (Number(item.readCount) || 0);
          if (type == 4) {
            nums2 = item.workload;
          }
        }
      });
      return `${nums1}/${nums2}`;
    },
    getListNums4(list, type) {
      let nums1 = 0;

      list.detailVOList.forEach((item) => {
        if (item.type == type) {
          nums1 = nums1 + (Number(item.readCount) || 0);
        }
      });

      return `${nums1}/${nums1 + Number(list.arbitrationWorkload)}`;
    },
  },
  props: {
    // 用来判断是第一个页面还是详情页面
    showTableType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tableData: [],
      v: "",
      groupScoringMode: 0,
      showSchoolName: false,
      groupRollingMethod: 0,
    };
  },
  created() {
    this.userInfo = getStore({ name: "userInfo" });
  },
  methods: {
    getRollNum(row) {
      let readCount = row.readCount || 0;
      let denominator = row.denominator || 0;
      let needDenominator = row.needDenominator;

      denominator = Number(denominator);

      if (
        (row.needDenominator || row.needDenominator === 0) &&
        row.needDenominator <= denominator
      ) {
        return readCount + "/" + needDenominator;
      } else {
        return readCount + "/" + denominator;
      }
    },
    getRollErr(row) {
      let type = true;
      if (row.denominator <= row.readCount) {
        type = false;
      }
      if (
        (row.needDenominator || row.needDenominator === 0) &&
        row.needDenominator <= row.readCount
      ) {
        type = false;
      }
      if (this.groupScoringMode == 1 && this.rollingMethod == 0) {
        if (row.wholeGroupDenominator <= row.wholeGroupReadCount) {
          type = false;
        }
      }
      if (
        this.v.rollingMethod == 1 &&
        this.groupScoringMode == 1 &&
        this.groupRollingMethod == 0
      ) {
        if (row.wholeReadCount >= row.wholeDenominator) {
          type = false;
        }
      }
      if (
        row.wholeDenominator <= row.wholeReadCount &&
        this.v.rollingMethod == 0
      ) {
        type = false;
      }

      return type;
    },
    objectSpanMethod({ row, columnIndex }) {
      let { ruleScore } = row;

      if (this.groupScoringMode == 1 && this.v.rollingMethod == 0) {
        // 分组阅卷详情
        // 第一列 第二列 三 四 需要合并的判断
        if (
          columnIndex == 0 ||
          columnIndex == 1 ||
          columnIndex == 2 ||
          columnIndex == 3
        ) {
          let rowspan = 1;
          let colspan = 1;
          // 如果是需要合并的单元格
          if (row.showMultiseriate && row.questionIndex == 0) {
            if (ruleScore == 1) {
              rowspan = 2;
            }
            if (ruleScore == 2 || ruleScore == 4) {
              rowspan = 3;
            }
            if (ruleScore == 3) {
              rowspan = 4;
            }
          }
          if (!row.showMultiseriate && row.questionIndex == 0) {
            if (ruleScore == 1) {
              rowspan = 1;
            }
            if (ruleScore == 2 || ruleScore == 4) {
              rowspan = 2;
            }
            if (ruleScore == 3) {
              rowspan = 3;
            }
          }
          if (row.questionIndex > 0) {
            rowspan = 0;
          }
          return {
            rowspan: rowspan,
            colspan: colspan,
          };
        }
        // 第六列需要合并的判断
        if (columnIndex == 5) {
          let rowspan = 1;
          let colspan = 1;
          if (
            row.showMultiseriate &&
            row.questionIndex == 0 &&
            ruleScore == 1
          ) {
            rowspan = 2;
          }
          if (
            row.showMultiseriate &&
            row.questionIndex > 0 &&
            (ruleScore == 2 || ruleScore == 4)
          ) {
            rowspan = 2;
          }
          if (row.showMultiseriate && row.questionIndex > 1 && ruleScore == 3) {
            rowspan = 2;
          }
          if (row.type == 19) {
            rowspan = 0;
          }
          return {
            rowspan: rowspan,
            colspan: colspan,
          };
        }
      } else {
        //如果是单校校内学校详情 或者分组校内组内抽卷
        if (
          (this.v.rollingMethod == 0 && this.showTableType == 1) ||
          (this.v.rollingMethod == 1 &&
            this.groupScoringMode == 1 &&
            this.groupRollingMethod == 0)
        ) {
          // 第一列 第二列 三 需要合并的判断
          if (columnIndex == 0 || columnIndex == 1 || columnIndex == 2) {
            let rowspan = 1;
            let colspan = 1;
            // 如果是需要合并的单元格
            if (row.showMultiseriate && row.questionIndex == 0) {
              if (ruleScore == 1) {
                rowspan = 2;
              }
              if (ruleScore == 2 || ruleScore == 4) {
                rowspan = 3;
              }
              if (ruleScore == 3) {
                rowspan = 4;
              }
            }
            if (!row.showMultiseriate && row.questionIndex == 0) {
              if (ruleScore == 1) {
                rowspan = 1;
              }
              if (ruleScore == 2 || ruleScore == 4) {
                rowspan = 2;
              }
              if (ruleScore == 3) {
                rowspan = 3;
              }
            }
            if (row.questionIndex > 0) {
              rowspan = 0;
            }
            return {
              rowspan: rowspan,
              colspan: colspan,
            };
          }
          // 第四列需要合并的判断
          if (columnIndex == 3) {
            return {
              rowspan: 1,
              colspan: 1,
            };
          }
          // 第五列需要合并的判断
          if (columnIndex == 4) {
            let rowspan = 1;
            let colspan = 1;
            if (
              row.showMultiseriate &&
              row.questionIndex == 0 &&
              ruleScore == 1
            ) {
              rowspan = 2;
            }
            if (
              row.showMultiseriate &&
              row.questionIndex > 0 &&
              (ruleScore == 2 || ruleScore == 4)
            ) {
              rowspan = 2;
            }
            if (
              row.showMultiseriate &&
              row.questionIndex > 1 &&
              ruleScore == 3
            ) {
              rowspan = 2;
            }
            if (row.type == 19) {
              rowspan = 0;
            }
            return {
              rowspan: rowspan,
              colspan: colspan,
            };
          }
        } else {
          // 第一列 第二列 需要合并的判断
          if (columnIndex == 1 || columnIndex == 0) {
            let rowspan = 1;
            let colspan = 1;
            // 如果是需要合并的单元格
            if (row.showMultiseriate && row.questionIndex == 0) {
              if (ruleScore == 1) {
                rowspan = 2;
              }
              if (ruleScore == 2 || ruleScore == 4) {
                rowspan = 3;
              }
              if (ruleScore == 3) {
                rowspan = 4;
              }
            }
            if (!row.showMultiseriate && row.questionIndex == 0) {
              if (ruleScore == 1) {
                rowspan = 1;
              }
              if (ruleScore == 2 || ruleScore == 4) {
                rowspan = 2;
              }
              if (ruleScore == 3) {
                rowspan = 3;
              }
            }
            if (row.questionIndex > 0) {
              rowspan = 0;
            }
            return {
              rowspan: rowspan,
              colspan: colspan,
            };
          }
          // 第三列需要合并的判断
          if (columnIndex == 2) {
            return {
              rowspan: 1,
              colspan: 1,
            };
          }
          // 第四列需要合并的判断
          if (columnIndex == 3) {
            let rowspan = 1;
            let colspan = 1;
            if (
              row.showMultiseriate &&
              row.questionIndex == 0 &&
              ruleScore == 1
            ) {
              rowspan = 2;
            }
            if (
              row.showMultiseriate &&
              row.questionIndex > 0 &&
              (ruleScore == 2 || ruleScore == 4)
            ) {
              rowspan = 2;
            }
            if (
              row.showMultiseriate &&
              row.questionIndex > 1 &&
              ruleScore == 3
            ) {
              rowspan = 2;
            }
            if (row.type == 19) {
              rowspan = 0;
            }
            return {
              rowspan: rowspan,
              colspan: colspan,
            };
          }
        }
      }

      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    getErr(row, indexPaper) {
      let nums1 = 0;
      let nums2 = 0;
      let type = true;
      let taskAll = indexPaper.normalAnwserSheet - row.isBlankCount;
      if (row.ruleScore != 1) {
        taskAll = taskAll * 2;
      }
      row.teacherList.map((item) => {
        nums1 = nums1 + (Number(item.readCount) || 0);
        nums2 = nums2 + (Number(item.workload) || 0);
      });

      // if (row.ruleScore != 1) {
      let arr = row.detailVOList.filter((item) => item.type == 19);
      if (arr.length > 0) {
        let readCount19 = arr[0].readCount || 0;
        if (row.ruleScore != 1) {
          readCount19 = readCount19 * 2;
        }
        nums1 = nums1 + Number(readCount19);
      }
      if (
        (row.needDenominator || row.needDenominator === 0) &&
        row.needDenominator <= row.readCount
      ) {
        type = false;
      }
      // 如果已阅量大于任务数量 不变红
      if (nums1 >= nums2) {
        type = false;
      }
      // 如果已阅量大于答题卡总数 不变红

      if (nums1 >= taskAll) {
        if (this.v.scoringMode != 2 && this.v.rollingMethod != 0) {
          type = false;
        }
      }
      if (this.v.scoringMode == 1) {
        let nums = row.workloadAll;
        if (row.ruleScore != 1) {
          nums = nums * 2;
        }
        if (row.readCount >= nums) {
          type = false;
        }
      }
      // 已阅量完成  则变灰
      if (row.readCount >= row.denominator) {
        type = false;
      }
      if (
        this.v.rollingMethod == 1 &&
        this.groupScoringMode == 1 &&
        this.groupRollingMethod == 0
      ) {
        if (row.wholeReadCount >= row.wholeDenominator) {
          type = false;
        }
      }
      if (this.groupScoringMode == 1 && this.rollingMethod == 0) {
        if (row.wholeGroupReadCount >= row.wholeGroupDenominator) {
          type = false;
        }
      }

      // 总数够了  不变红
      if (
        row.wholeReadCount >= row.wholeDenominator &&
        this.v.rollingMethod == 0 &&
        this.showTableType == 1
      ) {
        type = false;
      }
      return type;
    },
    getType3(row) {
      let { workloadThree } = row;
      if (
        this.v.rollingMethod == 0 &&
        this.$route.name != "unionMarkingProgress"
      ) {
        return false;
      } else {
        if (workloadThree > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    getNumsT(list, type) {
      let nums1 = 0;
      let nums2 = 0;
      list.forEach((item) => {
        if (item.type == type) {
          nums1 = nums1 + (Number(item.readCount) || 0);
          nums2 = nums2 + (Number(item.workload) || 0);
        }
      });
      return nums2 > nums1 ? true : false;
    },
    getNums(list, type) {
      let nums1 = 0;

      list.forEach((item) => {
        if (type == 1) {
          nums1 = nums1 + (Number(item.readCount) || 0);
        } else {
          nums1 = nums1 + (Number(item.workload) || 0);
        }
      });
      return `${nums1}`;
    },
    showTeacher(item, row) {
      if (item.isSchool) {
        this.goDetails(item);
        return;
      }
      this.$refs.reviewSchedule.init(item, row);
    },
    goDetails(item) {
      let { teacherId, groupId } = item;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      let data = {
        schoolId: teacherId,
        groupId: groupId,
      };
      data.fromUrl = this.$route.name;
      data.examPaperId = item.examPaperId;
      let url = "schoolProgress";
      if (groupId) {
        url = "groupProgress";
      }
      if (this.$route.query.groupId) {
        data.groupId = this.$parent.search.groupId;
      }
      this.$router.push({
        name: url,
        query: Object.assign(query, data),
      });
    },
    getShow(v) {
      let { readStatus } = this.$parent.search;
      let { typeSucc, typeErr, readCount } = v;
      let type = false;
      if (readStatus === 0) {
        if (readCount == 0 && !typeSucc && typeErr) {
          type = true;
        }
      }
      if (readStatus === 1) {
        if (readCount != 0 && typeErr) {
          type = true;
        }
      }
      if (readStatus === 2) {
        if (!typeErr && typeSucc) {
          type = true;
        }
      }
      if (readStatus === "") {
        type = true;
      }
      if (readStatus === 3) {
        if (!typeSucc) {
          type = true;
        }
      }

      return type;
    },
    getType(item, type, v, row) {
      // item 当前教师 ，type：类型 1是绿色  2是红色 v：科目列表 row ：试题列表

      let retType = false;
      let rowWorkload = 0;
      let workload = Number(item.workload || 0);
      let taskAll = v.normalAnwserSheet - row.isBlankCount;
      if (row.ruleScore != 1) {
        taskAll = taskAll * 2;
      }
      // if (row.questionNum == "15-2") {
      //   console.log(taskAll);
      // }
      // 须阅量任务总数
      let workloadAll = 0;
      if (row) {
        workloadAll = Number(row.denominator || 0);
      }
      let readCount = Number(item.readCount);
      let readCountAll = 0;
      if (row) {
        readCountAll = Number(row.readCount);
      }

      if (type == 1) {
        if (workload && workload <= readCount) {
          retType = true;
        }
        if (
          this.v.rollingMethod == 1 &&
          this.groupScoringMode == 1 &&
          this.groupRollingMethod == 0
        ) {
          if (row.wholeReadCount >= row.wholeDenominator) {
            retType = true;
          }
        }
        if (workloadAll <= readCountAll && readCountAll) {
          retType = true;
        }
        if (
          (row.needDenominator || row.needDenominator === 0) &&
          row.needDenominator <= row.readCount
        ) {
          retType = true;
        }

        let rowReadCount = 0;
        row.detailVOList.map((index) => {
          if (index.type == item.type) {
            rowReadCount = Number(index.readCount) + rowReadCount;
            if (index.type == 3) {
              rowWorkload = rowWorkload + Number(index.readCount);
            }
            if (index.type == 1) {
              rowWorkload = rowWorkload + Number(index.readCount);
            }
          }
        });
        if (item.type == 1) {
          let arr = row.detailVOList.filter((item) => item.type == 19);
          if (arr.length > 0) {
            let readCount19 = arr[0].readCount || 0;
            if (row.ruleScore != 1) {
              readCount19 = readCount19 * 2;
            }
            rowReadCount = rowReadCount + Number(readCount19);
          }
        }
        if (item.type == 3) {
          rowWorkload = rowWorkload + Number(row.workloadThree);
          if (rowReadCount >= rowWorkload) {
            retType = true;
          }
          // 判断下 全局情况  内部页面直接置灰
          if (
            this.v.rollingMethod == 0 &&
            this.$route.name != "unionMarkingProgress"
          ) {
            retType = true;
          }
        }
        if (rowReadCount == workloadAll) {
          retType = true;
        }
        if (item.type == 1) {
          if (rowReadCount >= taskAll) {
            // retType = true;
            if (this.v.scoringMode != 2 && this.v.rollingMethod != 0) {
              retType = true;
            }
          }
        }
        if (
          row.wholeReadCount >= row.wholeDenominator &&
          this.v.rollingMethod == 0 &&
          this.showTableType == 1
        ) {
          retType = true;
        }

        if (item.type == 4) {
          if (row.ifSingle == 1) {
            if (row.arbitrationWorkload == 0) {
              retType = true;
            }
          } else {
            let nums1 = 0;
            let nums2 = 0;
            row.detailVOList.map((index) => {
              if (index.type == item.type) {
                nums1 = nums1 + (Number(index.readCount) || 0);
                nums2 = nums2 + (Number(index.workload) || 0);
              }
            });
            if (nums1 >= nums2) {
              retType = true;
            }
          }
        }

        if (this.groupScoringMode == 1 && this.rollingMethod == 0) {
          if (row.wholeGroupReadCount >= row.wholeGroupDenominator) {
            type = true;
          }
        }
        // 最后一级判断  如果是选做题 没有总任务量  就显示为灰色
        if (row && row.isOption == 1 && readCount == 0 && workloadAll == 0) {
          retType = true;
        }
      } else {
        if (workload > readCount) {
          retType = true;
        }
        if (
          (row.needDenominator || row.needDenominator === 0) &&
          row.needDenominator <= row.readCount
        ) {
          retType = false;
        }
        if (
          this.v.rollingMethod == 1 &&
          this.groupScoringMode == 1 &&
          this.groupRollingMethod == 0
        ) {
          if (row.wholeReadCount >= row.wholeDenominator) {
            retType = false;
          }
        }
        if (
          row.wholeReadCount >= row.wholeDenominator &&
          this.v.rollingMethod == 0 &&
          this.showTableType == 1
        ) {
          retType = false;
        }
        let rowReadCount = 0;

        row.detailVOList.map((index) => {
          if (index.type == item.type) {
            rowReadCount = Number(index.readCount) + rowReadCount;

            // if(item.type==)
            if (index.type == 3) {
              rowWorkload = rowWorkload + Number(index.readCount);
            }
          }
        });

        if (item.type == 1) {
          let arr = row.detailVOList.filter((item) => item.type == 19);
          if (arr.length > 0) {
            let readCount19 = arr[0].readCount || 0;
            if (row.ruleScore != 1) {
              readCount19 = readCount19 * 2;
            }
            rowReadCount = rowReadCount + Number(readCount19);
          }
        }
        if (item.type == 3) {
          rowWorkload = rowWorkload + Number(row.workloadThree);
          if (rowReadCount >= rowWorkload) {
            retType = false;
          }
          if (row.workloadThree == 0) {
            retType = false;
          }
          if (
            this.v.rollingMethod == 0 &&
            this.$route.name != "unionMarkingProgress"
          ) {
            retType = false;
          }
        }
        if (item.type == 1) {
          if (rowReadCount >= taskAll) {
            if (this.v.scoringMode != 2 && this.v.rollingMethod != 0) {
              retType = false;
            }
          }
        }

        if (rowReadCount == workloadAll) {
          retType = false;
        }
        if (this.groupScoringMode == 1 && this.rollingMethod == 0) {
          if (row.wholeGroupReadCount >= row.wholeGroupDenominator) {
            type = false;
          }
        }

        if (workloadAll <= readCountAll && readCountAll) {
          retType = false;
        }
        if (item.type == 4) {
          retType = true;
          if (row.ifSingle == 1) {
            if (row.arbitrationWorkload == 0) {
              retType = false;
            }
          } else {
            let nums1 = 0;
            let nums2 = 0;
            retType = false;
            row.detailVOList.map((index) => {
              if (index.type == item.type) {
                nums1 = nums1 + (Number(index.readCount) || 0);
                nums2 = nums2 + (Number(index.workload) || 0);
              }
            });

            if (nums1 < nums2) {
              retType = true;
            }
          }
        }

        // 最后一级判断  如果是选做题 没有总任务量  就显示为灰色
        if (row && row.isOption == 1 && readCount == 0 && workloadAll == 0) {
          retType = false;
        }
      }

      if (this.v.scoringMode == 1 && item.type == 1) {
        let nums = row.workloadAll;
        if (row.ruleScore != 1) {
          nums = nums * 2;
        }
        if (row.readCount >= nums) {
          if (type == 2) {
            retType = false;
          }
          if (type == 1) {
            retType = true;
          }
        }
      }

      return retType;
    },
    init(v, data) {
      if (this.$route.query.groupId && this.$route.name == "schoolProgress") {
        if (v.groupList) {
          v.groupList.map((index) => {
            if (index.id == this.$route.query.groupId) {
              this.groupScoringMode = index.scoringMode;
              this.groupRollingMethod = index.rollingMethod;
            }
          });
        }
      }
      this.v = v;

      this.schoolList = this.$parent.schoolList;
      let arr = [];
      // console.log(JSON.parse(JSON.stringify(data)));
      // 重新生成数组  用来多行表格合并为单个表格
      data.map((item) => {
        if (item.isOption == 1 && !item.examQuestionGroup) {
          item.ruleScore = 1;
        }
        let index = JSON.parse(JSON.stringify(item));
        let teacherList = JSON.parse(JSON.stringify(item.detailVOList));

        // console.log(index.ruleScore);
        if (index.ruleScore == 1) {
          index.teacherList = teacherList.filter((i) => i.type == 1);
          index.type = 1;
          let indexArr = teacherList.filter((i) => i.type == 19);
          // 添加判断  当前存在直接打分  需要合并
          if (indexArr.length > 0 || index.waitAbnormalCount > 0) {
            index.showMultiseriate = true;
          }
          index.questionIndex = 0;
          arr.push(index);
          index = JSON.parse(JSON.stringify(item));
          index.teacherList = teacherList.filter((i) => i.type == 19);
          // 添加判断  当前存在直接打分  需要合并
          if (index.teacherList.length > 0 || index.waitAbnormalCount > 0) {
            index.type = 19;
            index.questionIndex = 1;
            arr.push(index);
          }
        }
        if (index.ruleScore == 2 || index.ruleScore == 4) {
          index.teacherList = teacherList.filter((i) => i.type == 1);
          index.type = 1;
          let indexArr = teacherList.filter((i) => i.type == 19);
          // 添加判断  当前存在直接打分  需要合并
          if (indexArr.length > 0 || index.waitAbnormalCount > 0) {
            index.showMultiseriate = true;
          }
          index.questionIndex = 0;
          arr.push(index);

          index = JSON.parse(JSON.stringify(item));

          index.teacherList = teacherList.filter((i) => i.type == 4);
          index.type = 4;
          index.questionIndex = 1;
          // 添加判断  当前存在直接打分  需要合并
          if (indexArr.length > 0 || index.waitAbnormalCount > 0) {
            index.showMultiseriate = true;
          }
          arr.push(index);
          index = JSON.parse(JSON.stringify(item));
          index.teacherList = teacherList.filter((i) => i.type == 19);
          // 添加判断  当前存在直接打分  需要合并
          if (index.teacherList.length > 0 || index.waitAbnormalCount > 0) {
            index.type = 19;
            index.questionIndex = 2;
            arr.push(index);
          }
        }
        if (index.ruleScore == 3) {
          index.teacherList = teacherList.filter((i) => i.type == 1);
          index.type = 1;
          let indexArr = teacherList.filter((i) => i.type == 19);
          // 添加判断  当前存在直接打分  需要合并
          if (indexArr.length > 0 || index.waitAbnormalCount > 0) {
            index.showMultiseriate = true;
          }
          index.questionIndex = 0;
          arr.push(index);
          index = JSON.parse(JSON.stringify(item));
          // if (index.isOption == 1 && !item.examQuestionGroup) return;
          index.teacherList = teacherList.filter((i) => i.type == 3);
          index.type = 3;
          index.questionIndex = 1;
          arr.push(index);
          index = JSON.parse(JSON.stringify(item));
          index.teacherList = teacherList.filter((i) => i.type == 4);
          index.type = 4;
          index.questionIndex = 2;
          // 添加判断  当前存在直接打分  需要合并
          if (indexArr.length > 0 || index.waitAbnormalCount > 0) {
            index.showMultiseriate = true;
          }
          arr.push(index);
          index = JSON.parse(JSON.stringify(item));
          index.teacherList = teacherList.filter((i) => i.type == 19);
          // 如果当前存在直接打分  就在添加一行
          if (index.teacherList.length > 0 || index.waitAbnormalCount > 0) {
            index.type = 19;
            index.questionIndex = 3;
            arr.push(index);
          }
        }
      });

      arr = arr.map((item) => {
        item.teacherList.map((index) => {
          index.typeSucc = this.getType(index, 1, this.v, item);
          index.typeErr = this.getType(index, 2, this.v, item);
        });
        return item;
      });
      // console.log(JSON.parse(JSON.stringify(arr)));
      this.tableData = arr;
    },
  },
};
</script>
<style scoped lang="scss">
.questionTab {
  ::v-deep .el-table__cell {
    padding-bottom: 0;
  }
  .el-icon-question {
    color: #2474ed;
  }
  .succ {
    color: #82848a !important;
  }
  .err {
    color: #f56c6c !important;
  }
  .err-tips {
    color: #faad14 !important;
  }
  .filter-item {
    display: none;
  }
  .show-tips {
    display: block;
  }
  .teacher-item {
    border: 1px solid;
    padding: 4px 8px;
    border-radius: 4px;
  }

  .task-load {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    border-top: 1px solid #ebeef5;
    padding: 6.5px 10px;
    padding-bottom: 0;
    .task-nums-list {
      color: #161817;
      margin-right: 12px;
      margin-bottom: 12px;
      text-align: left;
    }
    .task-item {
      cursor: pointer;
    }
    &:first-child {
      border-top: none;
    }
    .task-load-title {
      color: #d8d8d8;
    }
  }
  .task-load-opt {
    flex-wrap: nowrap;
    .shik-tips {
      flex-shrink: 0;
    }
  }
  .el-table__header {
    th {
      padding: 18px 0;
    }
  }
  .cell {
    padding: 0;
  }
  .check-tips {
    position: absolute;
    right: 18px;
  }
}
</style>
